import React from 'react'

const Content = () => {
  return (
    <div className='mainContentSection'>
        <div className='mainContentSectionDiv'>
            <h2>Your Awesome Business Theme</h2>
            <p>Lorem ipsum is simply dummy text of the printing and typesetiing industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s.</p>
        </div>
        <button>READ MORE</button>
    </div>
  )
}

export default Content